.mycontainer {
    height: calc(100% - 200px);
    background-color: blue;
}

.wrapper {
    height: 100vh;
    display: flex;
    /* Direction of the items, can be row or column */
    flex-direction: column;
}

main {
    flex: 1;
    padding: 5px;
}

.sidebar {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    ;
    margin: 0;
    padding: 10px;
    width: 250px;
    font-size: 12px;
    position: absolute;

}

.sidebar a {
    display: block;
    color: black;
    padding: 5px;
    text-decoration: none;
}

.sidebar a.active {
    background-color: #04AA6D;
    color: white;
}

.sidebar a:hover:not(.active) {
    background-color: rgb(223, 221, 221);
    color: black;
}

div.content {
    margin-left: 260px;
    padding: 1px 16px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
    ;
}

@media screen and (max-width: 700px) {
    .sidebar {
    background-color: transparent;
        width: 100%;
        height: auto;
        position: relative;
        margin: "100px";
    }

    .sidebar a {

        text-align: center;
        float: none;
        position: relative;
    }

    div.content {        
        
        width: 100%;
        margin-left: 0;
        margin-top: 16px;
    }
}