.lheader {
  padding: 45px 0 0;
  background: url(../assets/head.png) 0 0 repeat-x;
}

.mya {
  color: #fff;
}
.mya:hover {
  color: #00f;
}
